import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import "./Signin.css";

const Login = (props) => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [authError, setAuthError] = React.useState(false);
    const [unknownError, setUnknownError] = React.useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAuthError(false);
        setUnknownError(false);

        try {
            await axios.get("https://aclbqa.sisamlat.com/sanctum/csrf-cookie");
            const response = await axios.post("/login", {
                email: email,
                password: password,
            });

            if (response.status === 204 || response.status === 200) {
                const token = response.data.user.token;
                Cookies.set("token", token, { expires: 1 }); // Expira después de 1 día
                props.setToken(token);
                props.login();
                localStorage.setItem("session", 1);
                navigate("/home");
            }
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setAuthError(true);
            } else {
                setUnknownError(true);
                console.error(error);
            }
        }
    };

    // Redirige al usuario al inicio de sesión si no hay token
    React.useEffect(() => {
        const token = Cookies.get("token");
        if (!token) {
            navigate("/signin"); // Agrega esta función para redirigir al inicio de sesión
            localStorage.setItem("loggedIn", false);
        }
    }, []);

    return (
        <div className="login-bg">
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="login-card mx-auto">
                            <div className=" pt-5 color-header-login">
                                <h3>Iniciar Sesión</h3>
                            </div>
                            <div className="login-card-form">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            className={
                                                "form-control" +
                                                (authError || unknownError
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            placeholder="Ingrese Usuario"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            className={
                                                "form-control" +
                                                (authError || unknownError
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            placeholder="Ingrese Contraseña"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            required
                                        />
                                    </div>
                                    {authError && (
                                        <div className="alert alert-danger">
                                            La contraseña o el usuario son
                                            inválidos, intente de nuevo.
                                        </div>
                                    )}
                                    {unknownError && (
                                        <div className="alert alert-danger">
                                            Hubo un error al enviar tus
                                            detalles.
                                        </div>
                                    )}
                                    <button type="submit" className="btn-login">
                                        Iniciar sesión
                                    </button>
                                </form>
                                <img
                                    src="../../../AClogistica.png"
                                    alt="Logo"
                                    className="logo"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
